import React, { useState, useEffect } from 'react';

import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Heading,
  Input,
  Image,
  Grid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  GridItem
} from '@chakra-ui/react';

import { saveAsJpeg, saveAsPng } from 'save-html-as-image';

function App() {
  const handleDownloadAsImage = (method) => {
    const element = document.getElementById('download-area');

    if (method === 'png') {
      saveAsPng(element, {}, { backgroundColor: 'white' });
      return;
    }

    saveAsJpeg(element, {}, { backgroundColor: 'white' });
  };

  const [sliderimgValue, setSliderimgValue] = useState(50)
  const [sliderMessage1Value, setSliderMessage1Value] = useState(0)
  const [sliderMessage2Value, setSliderMessage2Value] = useState(10)
  const [data, setData] = useState(null);
  const [option, setOption] = useState(0);
  const [imageSrc, setImageSrc] = useState('');
  const [subTitle, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const regex = /(?<=<h2>)(.*?)(?=<\/h2>)/gs;

  useEffect(() => {
      fetch('https://cidadeazulnoticias.com.br/wp-json/wp/v2/posts?per_page=10&page=1&_embed')
          .then(response => response.json())
          .then(jsonData => {
            setData(jsonData);
            setImageSrc(jsonData[option]._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url);
            let match = regex.exec(jsonData[option].content.rendered);
            let croppedText = match[1].replace(/<[^>]*>/g, '');
            setSubtitle(croppedText)
          })
          .catch(error => console.log(error));
  }, []);

  if (!data) {
      return <div>Loading...</div>;
  }

  const handleChange = (event, index, option) => {
    const { value } = event.target;
    const newData = [...data];
    if(option === 'title.rendered'){
      newData[index]['title']['rendered'] = value;
    } else {
      newData[index][option] = value;
    }
    console.log(newData[index])
    setData(newData);
  };

const handleFileSelect = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onloadend = () => {
    setImageSrc(reader.result);
  }

  reader.readAsDataURL(file);
}




  //featuredImage = 'https://images.pexels.com/photos/20787/pexels-photo.jpg'
  //featuredImage = 'https://s3.amazonaws.com/cidadeazulnoticias.com.br/wp-content/uploads/2023/01/12124909/supermercado-economia-inflacao.jpg';
  //featuredImage = 'https://s3.cidadeazulnoticias.com.br/wp-content/uploads/2023/01/12124909/supermercado-economia-inflacao.jpg';
  //setFeaturedImage('https://d29l6gveyjgh9m.cloudfront.net/wp-content/uploads/2023/01/12124909/supermercado-economia-inflacao.jpg')

return (
  <>
  <div className="container-fluid">
    <div className="row">
      <div className="col-4">
        <div className="col-12">
          <button type="button" className="btn btn-light" onClick={() => handleDownloadAsImage('jpg')}>Download document as JPG</button>
          <button type="button" className="btn btn-light" onClick={() => handleDownloadAsImage('png')}>Download document as PNG</button>
        </div>
        <div className="col-12">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">Selecione o post</label>
          <select className="form-select" aria-label="Default select example" value={option} onChange={(e) => { setOption(e.target.value) }}>
            <option value="0">{data[0].title.rendered}</option>
            <option value="1">{data[1].title.rendered}</option>
            <option value="2">{data[2].title.rendered}</option>
            <option value="3">{data[3].title.rendered}</option>
            <option value="4">{data[4].title.rendered}</option>
            <option value="5">{data[5].title.rendered}</option>
            <option value="6">{data[6].title.rendered}</option>
            <option value="7">{data[7].title.rendered}</option>
            <option value="8">{data[8].title.rendered}</option>
          </select>

          <label htmlFor="exampleFormControlTextarea1" className="form-label">Texto principal</label>
          <textarea className="form-control" style={{height: "100px"}} type="text" value={data[option].title.rendered} onChange={(e) => handleChange(e, option, 'title.rendered')}></textarea>

          <label htmlFor="exampleFormControlTextarea1" className="form-label">Posição do texto 1</label>
            <Slider aria-label='slider-ex-6' onChange={setSliderMessage1Value} defaultValue={sliderMessage1Value}>
              <SliderMark
                value={sliderMessage1Value}
                textAlign='center'
                bg='blue.500'
                color='white'
                mt='-10'
                ml='-5'
                w='12'
              >
                {sliderMessage1Value}%
              </SliderMark>
              <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
          <select className="form-select" defaultValue={"0"}>
            <option value="0">Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>

          <label htmlFor="exampleFormControlTextarea1" className="form-label">Texto secundario</label>
          <textarea className="form-control" style={{height: "100px"}} type="text" name='slug' value={subtitle} onChange={(e) => handleChange(e, option, 'slug')}></textarea>

          <label htmlFor="exampleFormControlTextarea1" className="form-label">Posição do texto 2</label>
            <Slider aria-label='slider-ex-6' onChange={setSliderMessage2Value} defaultValue={sliderMessage2Value}>
              <SliderMark
                value={sliderMessage2Value}
                textAlign='center'
                bg='blue.500'
                color='white'
                mt='-10'
                ml='-5'
                w='12'
              >
                {sliderMessage2Value}%
              </SliderMark>
              <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
            </Slider>
        </div>
        
        <label htmlFor="exampleFormControlTextarea1" className="form-label">Posição da imagem</label>
        <Slider aria-label='slider-ex-6' onChange={setSliderimgValue} defaultValue={sliderimgValue}>
          <SliderMark
            value={sliderimgValue}
            textAlign='center'
            bg='blue.500'
            color='white'
            mt='-10'
            ml='-5'
            w='12'
          >
            {sliderimgValue}%
          </SliderMark>
          <SliderTrack><SliderFilledTrack /></SliderTrack><SliderThumb />
        </Slider>

        <label htmlFor="exampleFormControlTextarea1" className="form-label">Trocar imagem:</label>
        <input type="file" accept="image/*" onChange={handleFileSelect} />

      </div>

      <div className="col-8">
          <div id="download-area" style={{
            height: '1920px', 
            width: '1080px', 
            padding: '160px 25px 140px 25px',
            backgroundImage: "url('"+imageSrc+"')",
            backgroundPosition: sliderimgValue+'%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            
            }} >
            <>
              <div className="row" style={{marginTop: sliderMessage1Value+'%'}}>
                <div className="col-12">
                  <div><span style={{backgroundColor: "azure",fontSize: '5rem', fontWeight: '300', lineHeight: '1.31'}} className="display-1 text-center">{data[option].title.rendered}</span></div>
                </div>
              </div>
              <div className="row" style={{paddingTop: sliderMessage2Value-sliderMessage1Value+'%'}}>
                <div className="col-12">
                  <div><span style={{backgroundColor: "azure"}} className="display-4 text-center">{subtitle}</span></div>
                </div>
              </div>
            </>
          </div>
      </div>
    </div>
  </div>

  </>
  );
}

export default App;